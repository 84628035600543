import i18next from 'i18next';
import moment from 'moment';

import { config } from '../config';

import axios from '../core/http';

import { APIcaller } from './APIcaller';

const baseApiUrl = config.baseApiUrl;

function getOrdersOfStores(storeIds) {
  const filter = {
    where: {
      storeId: { inq: storeIds },
    },
    order: 'orderDate DESC',
    include: [
      {
        relation: 'lnkSupplierOrderrel',
        scope: {
          order: 'name ASC',
        },
      },
      {
        relation: 'lnkStoreOrderrel',
        scope: {
          order: 'name ASC',
        },
      },
    ],
  };
  return axios
    .post(
      baseApiUrl + '/Orders/getByFilter',
      {
        filter,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getOrdersOfStoresPaginate(
  storeIds,
  supplierIds,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  params,
) {
  let requestUri = `${baseApiUrl}/order/orders?`;

  if (skip != null) {
    requestUri = requestUri.concat('skip=', `${skip}&`);
  }

  if (limit != null) {
    requestUri = requestUri.concat('limit=', `${limit}&`);
  }

  if (orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${orderBy}&`);
  }

  if (orderType != null) {
    requestUri = requestUri.concat('orderType=', `${orderType}&`);
  }

  if (search) {
    requestUri = requestUri.concat('search=', `${search}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (params) {
    requestUri = requestUri.concat(params);
  }

  let requestBody = { storeIds, supplierIds };

  return axios
    .post(requestUri, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getOrdersCountOfStores(storeIds, supplierIds, status, isCentralKitchenCount) {
  return axios
    .post(
      `${baseApiUrl}/order/orders/count`,
      { storeIds, supplierIds, status, isCentralKitchenCount },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getOrdersAmountForDay(storeId, date) {
  const startDay = moment(date).startOf('day').format();
  const endDay = moment(date).endOf('day').format();

  const filter = {
    where: {
      orderDate: { between: [startDay, endDay] },
      storeId: storeId,
    },
    order: 'startOrderDate ASC',
  };
  return axios
    .get(baseApiUrl + '/Stores/' + storeId + '/orders', {
      params: {
        filter,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data.length || 0;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchOrder(
  order,
  productOrders,
  isEditingDate,
  productOrdersSPToBeUpdated,
  type,
  sendParams,
) {
  return axios
    .patch(
      baseApiUrl + '/order',
      {
        type,
        order,
        sendParams,
        productOrders,
        isEditingDate,
        productOrdersSPToBeUpdated,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
          sha: process.env.REACT_APP_GIT_SHA,
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const getOrdersAnalyticsByStore = async (
  storeIds,
  startDate,
  endDate,
  getGrossSalaries = false,
) => {
  try {
    const data = await APIcaller.apiPost('/order/analytics-by-store', {
      storeIds,
      startDate,
      endDate,
      getGrossSalaries,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

const getTurnoverByStoreIdsAndDates = async (storeIds, startDate, endDate) => {
  try {
    const data = await APIcaller.apiPost('/order/turnover', {
      storeIds,
      startDate,
      endDate,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

function sendOrderByEmail(
  userId,
  storeId,
  supplierId,
  orderId,
  to,
  cc,
  bcc,
  body,
  imgBase64,
  userTimezone,
  withMail,
) {
  return axios
    .post(baseApiUrl + '/order/handleOrderByEmail', {
      userId,
      storeId,
      supplierId,
      orderId,
      to,
      cc,
      bcc,
      body,
      imgBase64,
      userTimezone,
      withMail,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
        sha: process.env.REACT_APP_GIT_SHA,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const deleteOrderById = (id) => APIcaller.apiDelete(`/order/${id}`);

function cancelReceptionOrderById(id) {
  return axios
    .post(`${baseApiUrl}/order/${id}/cancel-reception`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const getOrdersAnalyticsByCategory = async (storeIds, startDate, endDate, showErrorMessage) => {
  try {
    const data = await APIcaller.apiPost('/order/analytics-by-category', {
      storeIds,
      startDate,
      endDate,
    });

    return data;
  } catch (err) {
    if (showErrorMessage) {
      showErrorMessage(i18next.t('ORDERS.BY_CATEGORY.CATEGORIES_FETCH_ERROR'), 'error');

      return { total: {}, categories: [], dataByCategory: {}, turnoverByStoreId: {} };
    }

    throw err;
  }
};

const getInventoryForStores = (storeIds, startDate, endDate, showMessage, type, entityId) =>
  axios
    .post(`${baseApiUrl}/admin/stores/inventory`, {
      storeIds,
      startDate,
      endDate,
      type,
      entityId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function () {
      showMessage(i18next.t('ORDERS.BY_CATEGORY.INVENTORY_DATES_FETCH_FAILURE'), 'error');

      return { list: [] };
    });

function getOrderContentsExportDataByIds(ids) {
  return axios
    .post(`${baseApiUrl}/order/export/content`, {
      ids,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getOrderAggregatedExportDataByIds(ids) {
  return axios
    .post(`${baseApiUrl}/order/export/aggregate`, {
      ids,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getOrderAnomaliesExportDataByIds(ids) {
  return axios
    .post(`${baseApiUrl}/order/export/outliers`, {
      ids,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const getRecommandationsByBatch = (storeId, entityIds, endDate, startOrderDate, endOrderDate) =>
  axios
    .post(
      `${baseApiUrl}/order/stores/${storeId}/recommandation`,
      { entityIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
        params: {
          endDate,
          startOrderDate,
          endOrderDate,
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const sendGroupedOrder = (orderIds, sendParams) =>
  axios
    .post(`${baseApiUrl}/order/group`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      orderIds,
      sendParams,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

function markOrdersAsDraft(orderIds) {
  return axios
    .post(`${baseApiUrl}/order/mark-as-draft`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      orderIds,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const markPreparationAsPreparing = (orderId, productOrders) =>
  APIcaller.apiPost(`/order/${orderId}/status/in-preparation`, {
    productOrders,
  });

const markPreparationAsPrepared = (orderId, productOrders) =>
  APIcaller.apiPost(`/order/${orderId}/status/prepared`, {
    productOrders,
  });

const getOrdersAnalyticsBySupplierProduct = (
  clientId,
  startDate,
  endDate,
  storeIds,
  supplierIds,
  strategicOnly,
  categories,
  subCategories,
) =>
  APIcaller.apiPost('/order/analytics-by-supplier-products', {
    clientId,
    startDate,
    endDate,
    storeIds,
    supplierIds,
    strategicOnly,
    categories,
    subCategories,
  });

const getOrdersAnalyticsPricesEvolution = (
  clientId,
  startDate,
  endDate,
  storeIds,
  supplierIds,
  strategicOnly,
  categories,
  subCategories,
) =>
  APIcaller.apiPost('/order/analytics/prices', {
    clientId,
    startDate,
    endDate,
    storeIds,
    supplierIds,
    strategicOnly,
    categories,
    subCategories,
  });

const setCreditRequestProcessed = (clientId, orderIds) =>
  APIcaller.apiPost('/order/set-credit-request-processed', {
    clientId,
    orderIds,
  });

const purchaseOrderPreview = (order, productOrders, userTimezone = 'Europe/Paris') =>
  APIcaller.apiPost('/order/generate-purchase-order-preview', {
    order,
    productOrders,
    userTimezone,
  });

const creditOrderPreview = (order, productOrders) =>
  APIcaller.apiPost('/order/generate-credit-order-preview', {
    order,
    productOrders,
  });

const getOrderDetailsByOrderIds = (orderIds, productOrders) =>
  APIcaller.apiPost('/order/export/details', {
    ids: orderIds,
  });

const setOrderToInPreparation = (orderId, productOrders) =>
  APIcaller.apiPost(`/order/${orderId}/status/in-preparation`, {
    productOrders,
  });

const setOrderToPrepared = (orderId, productOrders, receptionComment, shouldGenerateInvoice) =>
  APIcaller.apiPost(`/order/${orderId}/status/prepared`, {
    productOrders,
    receptionComment,
    shouldGenerateInvoice,
  });

const setOrderToInvoiced = (orderId) => APIcaller.apiPost(`/order/${orderId}/status/invoiced`);

const generateDeliveryNotePreview = (order, productOrders, userTimezone = 'Europe/Paris') =>
  APIcaller.apiPost('/order/generate-delivery-note-preview', {
    order,
    productOrders,
    userTimezone,
  });

const checkCompaniesInformation = (storeId, supplierId) =>
  APIcaller.apiGet('/order/check-companies-information', { storeId, supplierId });

export default {
  getOrdersOfStores,
  getOrdersCountOfStores,
  getOrdersOfStoresPaginate,
  patchOrder,
  getOrdersAmountForDay,
  getOrdersAnalyticsByStore,
  getTurnoverByStoreIdsAndDates,
  sendOrderByEmail,
  deleteOrderById,
  cancelReceptionOrderById,
  getOrdersAnalyticsByCategory,
  getInventoryForStores,
  getOrderContentsExportDataByIds,
  getOrderAggregatedExportDataByIds,
  getOrderAnomaliesExportDataByIds,
  sendGroupedOrder,
  markOrdersAsDraft,
  markPreparationAsPreparing,
  markPreparationAsPrepared,
  getRecommandationsByBatch,
  getOrdersAnalyticsBySupplierProduct,
  getOrdersAnalyticsPricesEvolution,
  setCreditRequestProcessed,
  purchaseOrderPreview,
  creditOrderPreview,
  getOrderDetailsByOrderIds,
  setOrderToInPreparation,
  setOrderToPrepared,
  setOrderToInvoiced,
  generateDeliveryNotePreview,
  checkCompaniesInformation,
};
