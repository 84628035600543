import { APIcaller } from './APIcaller';
import axios from '../core/http';

import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

/***************/
/** Retailers **/
/***************/

const getRetailersOfClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/retailers`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

/***************/
/** Account **/
/***************/

const getAccountsOfClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/accounts`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
/******************/
/** Partnerships **/
/******************/

const getPartnershipsByClientId = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/partners`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const createPartnershipByClientIdAndPartnerId = (clientId, partnerId) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/partners/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      partnerId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const deletePartnershipByClientIdAndPartnerId = (clientId, partnerId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/partners/${partnerId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

/***************/
/** Languages **/
/***************/

const getAllLanguages = () => APIcaller.apiGet('/public/languages');

/*************/
/** Clients **/
/*************/

const getClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/Clients/${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const patchClientById = (clientId, fields) =>
  axios
    .patch(
      `${baseApiUrl}/admin/clients/${clientId}`,
      {
        client: { ...fields },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const userRegistration = (registerParams) => {
  const { firstName, lastName, languageId, password, clientId, userId, token } = registerParams;

  return axios
    .post(
      `${baseApiUrl}/admin/clients/${clientId}/users/${userId}/register`,
      {
        firstName,
        lastName,
        languageId,
        password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

/***************/
/** Features **/
/***************/

const patchPropsFeatureById = (clientId, featureId, props) =>
  axios
    .patch(
      `${baseApiUrl}/admin/clients/${clientId}/features/${featureId}`,
      {
        props,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createAccountRoleFeatureClientMappings = (clientId, roleId, featureClientMappingIds) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/roles/${roleId}/features`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      featureClientMappingIds,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

/************/
/** Roles **/
/************/

const createClientRole = (clientId, name) =>
  axios
    .post(
      `${baseApiUrl}/admin/clients/${clientId}/roles`,
      {
        name,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateEventSubCategoriesRoles = (clientId, roleId, eventSubCategoryIds) =>
  axios
    .patch(
      `${baseApiUrl}/admin/clients/${clientId}/roles/${roleId}/events`,
      { eventSubCategoryIds },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateRoleByRoleId = (clientId, roleId, role) =>
  axios
    .patch(`${baseApiUrl}/admin/clients/${clientId}/roles/${roleId}`, role, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getRolesByClientId = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/roles`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteRoleById = (clientId, roleId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/roles/${roleId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteAccountRoleFeatureClientMappings = (clientId, roleId, featureClientMappingIds) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/roles/${roleId}/features`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      data: {
        featureIds: featureClientMappingIds,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

export const createAndInviteUser = (clientId, userInformations) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/users`, {
      email: userInformations.email,
      accountRoleId: userInformations.roleId,
      associatedStoreIds: userInformations.associatedStoreIds,
      sendInvitation: userInformations.sendInvitation,
      languageId: userInformations.languageId,
      catalogId: userInformations.catalogId,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const sendInvitationEmailByAccountId = async (clientId, accountId) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/accounts/${accountId}/send-invitation`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createStore = (clientId, storeCreation) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/stores`, {
    ...storeCreation,
  });

const cloneProduction = (clientId, storeId, storeIdReference) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/stores/${storeId}/clone-production`, {
    storeIdReference,
  });

const cloneEvents = (clientId, storeId, storeIdReference) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/stores/${storeId}/clone-events`, {
      storeIdReference,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const cloneProductMix = (clientId, storeId, storeIdReference) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/stores/${storeId}/clone-product-mix`, {
      storeIdReference,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createOrUpdateBrandByBatch = (clientId, brands) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/brands/batch`, {
      brands,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createOrUpdateRetailerByBatch = (clientId, retailers) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/retailers/batch`, {
      retailers,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateIngredientPropertyByBatch = (clientId, ingredients) =>
  axios
    .patch(
      `${baseApiUrl}/admin/clients/${clientId}/ingredients`,
      {
        ingredientsToUpdate: { updates: ingredients },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getEntityBatchCreationTemplate = async (clientId) => {
  try {
    const { data } = await axios.get(
      `${baseApiUrl}/admin/clients/${clientId}/ingredients/batch-creation/template`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    );

    return data;
  } catch (error) {
    return [];
  }
};

const postEntityBatchCreateValidation = async (clientId, dataToValidate) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/admin/clients/${clientId}/ingredients/batch-creation/validation`,
      {
        importType: dataToValidate.importType,
        entities: dataToValidate.entities,
        categories: dataToValidate.categories,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    );

    return data;
  } catch (error) {
    if (error.response) {
      throw { data: error.response.data, statusCode: error.response.status };
    }

    throw error;
  }
};

const postEntityBatchCreation = async (clientId, entitiesToCreate) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/admin/clients/${clientId}/ingredients/batch-creation/import`,
      {
        importType: entitiesToCreate.importType,
        entities: entitiesToCreate.entities,
        categories: entitiesToCreate.categories,
        allergens: entitiesToCreate.allergens,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

/***************/
/** Locations **/
/***************/

const getLocationsByClientId = (clientId) =>
  APIcaller.apiGet(`/admin/clients/${clientId}/locations`);

/************/
/** Groups **/
/************/

const getGroupsOfClient = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/groups`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });

const createClientGroup = (clientId, name) =>
  axios
    .post(
      `${baseApiUrl}/admin/clients/${clientId}/groups`,
      {
        name,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateGroupByGroupId = (clientId, groupId, group) =>
  axios
    .patch(`${baseApiUrl}/admin/clients/${clientId}/groups/${groupId}`, group, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteGroupById = (clientId, groupId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/groups/${groupId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createGroupStoreGroupMappings = (clientId, groupId, storeIds) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/groups/${groupId}/stores`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      storeIds,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createStoreStoreGroupMappings = (clientId, storeId, groupIds) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/stores/${storeId}/groups`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      groupIds,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createOrUpdateGroupsByBatch = (clientId, groups) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/manage-groups`, { groups });

/********************/
/** Cashier Stores **/
/********************/

const deleteCashierStoreById = (cashierStoreId) =>
  axios
    .delete(`${baseApiUrl}/admin/cashier-stores/${cashierStoreId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createCashierStoreStoreCashierStoreMappings = (cashierStoreIds, storeId) =>
  axios
    .post(`${baseApiUrl}/admin/cashier-stores/mapping/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      cashierStoreIds,
      storeId,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const createClientLocation = (clientId, name, partnerId) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/locations`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
      name,
      partnerId,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateClientLocation = (clientId, locationId, { name, partnerId }) =>
  axios
    .patch(
      `${baseApiUrl}/admin/clients/${clientId}/locations/${locationId}`,
      { name, partnerId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const deleteLocationById = (clientId, locationId) =>
  axios
    .delete(`${baseApiUrl}/admin/clients/${clientId}/locations/${locationId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const checkedSupplierProducts = (clientId, supplierProducts) =>
  axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/supplier-products/excel/price/validate`, {
      supplierProducts,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data };
      }

      throw error;
    });

export function importSupplierProductsUpdatedPrices(clientId, supplierProducts) {
  return axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/supplier-products/excel/price/import`, {
      supplierProducts,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export function createSPbyBatch(
  clientId,
  supplierProducts,
  suppliers,
  entities,
  categories,
  subCategories,
  importType,
) {
  return axios
    .post(`${baseApiUrl}/admin/clients/${clientId}/supplier-products/batchCreation/import`, {
      supplierProducts,
      suppliers,
      entities,
      categories,
      subCategories,
      importType,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}
/**************/
/** Products **/
/**************/

const getCentralKitchenProductBatchCreationTemplate = async (clientId) =>
  APIcaller.apiGet(
    `/admin/clients/${clientId}/central-kitchen/products/batch-creation/template`,
    {},
    'blob',
  );

const getProductBatchCreationTemplate = async (clientId) => {
  const { data } = await axios.get(
    `${baseApiUrl}/admin/clients/${clientId}/products/batch-creation/template`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

const postProductsBatchCreationValidate = async (clientId, productsToCreate) => {
  const {
    products,
    brands,
    categories,
    subCategories,
    importType,
    associatedIngredient,
    associatedRecipe,
  } = productsToCreate;

  const { data } = await axios.post(
    `${baseApiUrl}/admin/clients/${clientId}/products/batch-creation/validate`,
    {
      products,
      brands,
      categories,
      subCategories,
      importType,
      associatedIngredient,
      associatedRecipe,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
    { responseType: 'blob' },
  );

  return data;
};

const postCentralKitchenProductsBatchCreationValidate = async (
  clientId,
  {
    centralKitchenProducts,
    categories,
    subCategories,
    importType,
    associatedIngredient,
    associatedRecipe,
  },
) =>
  APIcaller.apiPost(
    `/admin/clients/${clientId}/central-kitchen/products/batch-creation/validate`,
    {
      centralKitchenProducts,
      categories,
      subCategories,
      importType,
      associatedIngredient,
      associatedRecipe,
    },
    'blob',
  );

const postProductsBatchCreation = async (clientId, productsToCreate) => {
  const {
    products,
    brands,
    categories,
    subCategories,
    importType,
    associatedIngredient,
    associatedRecipe,
  } = productsToCreate;

  const { data } = await axios.post(
    `${baseApiUrl}/admin/clients/${clientId}/products/batch-creation/import`,
    {
      products,
      brands,
      categories,
      subCategories,
      importType,
      associatedIngredient,
      associatedRecipe,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

const postCentralKitchenProductsBatchCreation = async (
  clientId,
  centralKitchenProductsToCreate,
) => {
  const {
    centralKitchenProducts,
    categories,
    subCategories,
    importType,
    associatedIngredient,
    associatedRecipe,
  } = centralKitchenProductsToCreate;

  return APIcaller.apiPost(
    `/admin/clients/${clientId}/central-kitchen/products/batch-creation/import`,
    {
      centralKitchenProducts,
      categories,
      subCategories,
      importType,
      associatedIngredient,
      associatedRecipe,
    },
  );
};

/**********/
/* Stores */
/**********/

export const getStoresMappedToSupplierOfClient = (clientId, supplierId) =>
  APIcaller.apiGet(`/admin/clients/${clientId}/suppliers/${supplierId}/stores`);

/****************************************/
/**  InventoryListTemplates  **/
/****************************************/

const getInventoryListTemplates = async (
  clientId,
  { withMappings = false, filterByUserCatalog = false } = {},
) =>
  APIcaller.apiGet(`/admin/clients/${clientId}/inventory-list-templates`, {
    withMappings,
    filterByUserCatalog,
  });

const getInventoryListTemplateById = async (clientId, inventoryListTemplateId) =>
  APIcaller.apiGet(
    `/admin/clients/${clientId}/inventory-list-templates/${inventoryListTemplateId}`,
  );

const postInventoryListTemplates = async (clientId, inventoryListTemplates) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/inventory-list-templates`, {
    inventoryListTemplates,
  });

const assignSupplierProductsToInventoryListTemplates = async (
  clientId,
  supplierProductIds,
  inventoryListTemplateIds,
) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/inventory-list-templates/mappings`, {
    supplierProductIds,
    inventoryListTemplateIds,
  });

const getSPIdsMappedToTemplates = async (clientId, inventoryListTemplateIds) =>
  APIcaller.apiPost(
    `/admin/clients/${clientId}/supplier-products-mapped-to-inventory-list-templates`,
    {
      inventoryListTemplateIds,
    },
  );

const deleteInventoryListTemplates = async (clientId, inventoryListTemplateIds) =>
  APIcaller.apiDelete(`/admin/clients/${clientId}/inventory-list-templates`, {
    inventoryListTemplateIds,
  });

const createInventoryListTemplate = async (
  clientId,
  name,
  supplierProductIds = [],
  entityIds = [],
) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/inventory-list-template`, {
    name,
    supplierProductIds,
    entityIds,
  });

const updateInventoryListTemplate = async (
  clientId,
  inventoryListTemplateId,
  name = null,
  supplierProductIds = null,
  entityIds = null,
) =>
  APIcaller.apiPatch(
    `/admin/clients/${clientId}/inventory-list-templates/${inventoryListTemplateId}`,
    {
      name,
      supplierProductIds,
      entityIds,
    },
  );

const updateClientName = async (clientId, clientName) =>
  APIcaller.apiPatch(`/backoffice/general/general/update-client-name/${clientId}`, {
    clientName,
  });

const updateCategories = async (clientId, categories, type) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/categories`, {
    categories,
    type,
  });

const getCategoriesAndSubCategories = async (clientId, type) =>
  APIcaller.apiGet(`/admin/clients/${clientId}/categories-sub-categories/${type}`);

const createCategory = async (clientId, name, type) =>
  APIcaller.apiPost(`/admin/clients/${clientId}/category`, {
    name,
    type,
  });

export default {
  getRetailersOfClient,
  getAllLanguages,
  getClient,
  getAccountsOfClient,
  patchClientById,
  patchPropsFeatureById,
  createAccountRoleFeatureClientMappings,
  deleteAccountRoleFeatureClientMappings,
  deleteRoleById,
  createClientRole,
  updateEventSubCategoriesRoles,
  getRolesByClientId,
  updateRoleByRoleId,
  getPartnershipsByClientId,
  createPartnershipByClientIdAndPartnerId,
  deletePartnershipByClientIdAndPartnerId,
  deleteCashierStoreById,
  createAndInviteUser,
  sendInvitationEmailByAccountId,
  userRegistration,
  createStore,
  cloneProduction,
  cloneProductMix,
  cloneEvents,
  createOrUpdateBrandByBatch,
  createOrUpdateRetailerByBatch,
  updateIngredientPropertyByBatch,
  getGroupsOfClient,
  createClientGroup,
  updateGroupByGroupId,
  deleteGroupById,
  createGroupStoreGroupMappings,
  createStoreStoreGroupMappings,
  createOrUpdateGroupsByBatch,
  createCashierStoreStoreCashierStoreMappings,
  getLocationsByClientId,
  createClientLocation,
  updateClientLocation,
  deleteLocationById,
  checkedSupplierProducts,
  importSupplierProductsUpdatedPrices,
  createSPbyBatch,
  getEntityBatchCreationTemplate,
  postEntityBatchCreateValidation,
  postEntityBatchCreation,
  getProductBatchCreationTemplate,
  getCentralKitchenProductBatchCreationTemplate,
  postProductsBatchCreationValidate,
  postCentralKitchenProductsBatchCreationValidate,
  postProductsBatchCreation,
  postCentralKitchenProductsBatchCreation,
  getStoresMappedToSupplierOfClient,
  updateClientName,
  updateCategories,
  getCategoriesAndSubCategories,
  createCategory,

  /**  InventoryListTemplates  **/
  getInventoryListTemplates,
  postInventoryListTemplates,
  assignSupplierProductsToInventoryListTemplates,
  getSPIdsMappedToTemplates,
  deleteInventoryListTemplates,
  createInventoryListTemplate,
  getInventoryListTemplateById,
  updateInventoryListTemplate,
};
